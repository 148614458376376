.landing-header {
  height: $app-header-height;
  z-index: $app-header-zindex;
  background: var(--#{$prefix}app-header-bg);
  display: flex;
  transition: all 0.15s ease-in-out;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  position: fixed;
  width: 100%;
  & .menu-toggler {
    border: none;
    background: none;
    height: 100%;
    padding: $spacer * 0.5 $spacer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    & .bar {
      width: rem(26px);
      display: block;
      height: 1px;
      background: rgba(var(--#{$prefix}app-header-link-color-rgb), 0.4);
      transition: all 0.2s linear;

      & + .bar {
        margin-top: rem(4px);
      }
    }
    &:hover {
      & .bar {
        background: rgba(var(--#{$prefix}app-header-link-color-rgb), 0.75);
      }
    }
  }
  & .mobile-toggler {
    height: 100%;
    display: none;

    @if $enable-rtl {
      margin-left: auto;
    } @else {
      margin-right: auto;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
    }
  }
  & .desktop-toggler {
    height: 100%;
    display: flex;
    align-items: center;

    @if $enable-rtl {
      margin-left: auto;
    } @else {
      margin-right: auto;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  & .brand {
    height: 100%;
    width: 8rem;
    padding: 0 $spacer;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      display: none;
    }

    & .brand-logo {
      text-decoration: none;
      color: var(--#{$prefix}app-header-link-color);
      font-size: $font-size-base * 1.05;
      letter-spacing: 2px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md) {
        display: none;
      }

      & img {
        max-width: 100%;
        max-height: 100%;

        @if $enable-rtl {
          margin-left: $spacer;
        } @else {
          margin-right: $spacer;
        }
      }
      & .brand-img {
        width: rem(26px);
        height: rem(26px);
        background: var(--#{$prefix}app-header-brand-img);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        @if $enable-rtl {
          margin-left: $spacer;
        } @else {
          margin-right: $spacer;
        }

        & .brand-img-text {
          display: block;
          letter-spacing: initial;
          font-weight: $font-weight-bold;
          font-size: $font-size-base * 1.2;
        }
      }
    }
  }
  & .menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
    & .menu-item {
      /* height: 100%;
      display: flex;
      align-items: center; */

      & .menu-link {
        text-decoration: none;
        color: var(--#{$prefix}app-header-link-color);
        position: relative;
        padding: $spacer * 0.5 $spacer;
        display: flex;
        align-items: center;

        & .menu-img {
          width: rem(32px);
          height: rem(32px);

          @if $enable-rtl {
            margin-left: $spacer * 0.75;
          } @else {
            margin-right: $spacer * 0.75;
          }

          & img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 30px;
          }
        }
        & .menu-icon {
          font-size: rem(20px);
        }
        & .menu-badge {
          position: absolute;
          background: transparent;
          width: 6px;
          height: 6px;
          top: rem(8px);
          border-radius: 6px;

          @if $enable-rtl {
            left: $spacer;
          } @else {
            right: $spacer;
          }
        }
      }
    }
  }
  .menu-control {
    display: none;
  }
}

.vAygCK-api-load-alpha-banner {
  display: none;
}
gmp-map-3d {
  height: 100vh;
  border: none !important;
  outline: none !important;
  &::shadowroot canvas::focus-visible {
    /* Your styles for the canvas element */
    outline: none;
  }
}
*:focus {
  outline: none;
}
canvas {
  display: flex;
}
*::focus-visible {
  outline: none;
}
body {
  &::focus-visible {
    outline: none;
  }
}
